import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { AssetImage } from './image'
import SvgG11 from '../generated/svg/g1-1'
import SvgG12 from '../generated/svg/g1-2'
import SvgG21 from '../generated/svg/g2-1'
import SvgG22 from '../generated/svg/g2-2'
import SvgG31 from '../generated/svg/g3-1'
import SvgG32 from '../generated/svg/g3-2'
import SvgG33 from '../generated/svg/g3-3'
import SvgG41 from '../generated/svg/g4-1'
import SvgG42 from '../generated/svg/g4-2'
import { useSettings } from '../hooks/use-settings'
import SvgG52 from '../generated/svg/g52'
import SvgG51 from '../generated/svg/g51'

const Outer = styled.div``

const Inner = styled.div`
  padding-top: 3.375rem;
  padding-bottom: 3.375rem;
  & + & {
    border-top: 1px solid currentColor;
  }
`

const TopContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const TopItem = styled.div`
  display: flex;
  flex-direction: column;
  img {
    display: block;
    width: 100%;
    height: 0;
    flex: 1 1 0;
    object-fit: contain;
    align-self: stretch;
    @media (max-width: 40rem){
      height: 12rem;
      width: 100%;
    }
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Text1 = styled.div`
  font-size: 1.5rem;
  line-height: 1.4166666666666667;
  font-weight: 700;
  color: var(--color);
  margin-bottom: 1rem;
  ${TopContainer} + & {
    margin-top: 3rem;
  }
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
`

const Note1 = styled.div`
  font-size: 0.6875rem;
  line-height: 1.8181818181818181;
`

const UsageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StepImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const StepLabel = styled.div`
  padding: 0.75rem 1.625rem;
  font-size: 0.875rem;
  background-color: white;
  margin-top: 1.5rem;
`

const StepText = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1rem;
`

const Note2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  color: #CC4444;
  margin-top: 2rem;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`

const StepBoxNote = styled.div`
  background-color: color-mix(in lch, var(--color) 20%, transparent);
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 2rem;
`

const HorizontalContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.125rem;
  margin-top: 2rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const SvgContainer  = styled.div`
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

const TextConteiner = styled.div``

const Text3 = styled.div`
  font-size: 1.125rem;
  line-height: 1.5555555555555556;
  font-weight: 700;
`

const Text4 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1rem;
`

const Note3 = styled.div`
  font-size: 0.8125rem;
  line-height: 1.6923076923076923;
  font-weight: 500;
  margin-top: 1.75rem;
  padding: 0.5rem 0.75rem;
  background-color: white;
`

const List = styled.div`
`

const Item = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-left: 1em;
  &::before {
    content: '・';
    margin-left: -1em;
  }
`

const BottomContainer = styled.div`
  margin-top: 4.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BottomText = styled.div`
  font-size: 1.125rem;
  line-height: 1.3888888888888888;
  font-weight: 700;
  color: var(--color);
`

const Button = styled.a`
  display: block;
  font-size: 1.1897809505462646rem;
  line-height: 1.7499999499028416;
  padding: 0.75rem 6rem;
  font-weight: 700;
  background-color: var(--color);
  color: white;
  border-radius: 10rem;
  margin-top: 1.5rem;
`


type HowToProps = {

}
export const PeelCareHowTo: FunctionComponent<HowToProps> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer style={{'--color': '#5395A1'}}>
    <Inner>
      <TopContainer>
        <TopItem>
          <AssetImage src="g1-0.png" />
        </TopItem>
        <TopItem>
          <SvgG11 />
        </TopItem>
        <TopItem>
          <SvgG12 />
        </TopItem>
      </TopContainer>
      <Text1>
        初めてのご使用の前に
      </Text1>
      <Text2>
        目立たない部分に少し塗り、 2分ほど経っても刺激が出ていなければ全顔にお使いください。
      </Text2>
      <Note1>
        ※お肌が敏感な方、アレルギーをお持ちの方は、使用前に製品のパッチテストを実施していただくことをおすすめします。<br />
        ※お肌に傷・はれもの・湿疹など異常のある時は使用しないでください。
      </Note1>
    </Inner>
    <Inner>
      <Text1>
        ご使用方法
      </Text1>
      <UsageContainer>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi1-1.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 1
          </StepLabel>
          <StepText>
            一円玉大のセラムを手のひらにとります。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi1-2.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 2
          </StepLabel>
          <StepText>
          洗顔後の乾いた肌に目の周り・口元・生え際を避けて塗布し、やさしく馴染ませます。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi1-3.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 3
          </StepLabel>
          <StepText>
            【3.0】をご使用の方は、５分程度置いてから、ぬるま湯でしっかり洗い流してください。<br />
            【1.0・2.0】をご使用の方は、ざらつきが気になる場所には薄く重ね付けしていただけます。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi1-4.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 4
          </StepLabel>
          <StepText>
            しっかりセラムが肌に馴染むよう少し時間をおいてから、次のアイテムをお使いください。使用後は保湿ケアを入念に行なってください。
          </StepText>
        </StepItem>
      </UsageContainer>
      <Note2>
        ※使用中は、紫外線ダメージを受けやすいため、日中は日焼け止め等で紫外線対策をおこなってください。
      </Note2>
    </Inner>
    <Inner> 
      <Text1>
        必ずお読みください
      </Text1>
      <List>
        <Item>使用中にピリピリ感やほてり感を感じる可能性があります。強く感じた場合はすぐに水で洗い流し、治らない場合は洗顔料で洗い流しください。</Item>
        <Item>レチノールやハイドロキノンをお使いの日にはご使用しないでください</Item>
        <Item>急激に多い量を使うと肌が刺激に敏感になりやすくなるため肌の様子を確認しながら使用してください。</Item>
        <Item>本製品の使用後は肌が刺激に対して敏感になりやすくなります。普段お使いの化粧品でも刺激を感じることがありますので、ご注意ください。</Item>
        <Item>冬季や乾燥の多い季節、肌荒れを起こしている場合、刺激を強く感じる可能性があります。</Item>
      </List>
      <BottomContainer>
        <BottomText>
          肌が慣れてきたり、物足りない方はグレードアップの検討も可能です。
        </BottomText>
        <Button href={`${themeFileRootPath}/assets/SKNREMED_BRAND_GUIDE_BOOK.pdf`}>
          詳しくはこちら
        </Button>
      </BottomContainer>
    </Inner>
  </Outer>
}


export const RetinoDermHowTo: FunctionComponent<HowToProps> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer style={{'--color': '#E98760'}}>
    <Inner>
      <TopContainer>
        <TopItem>
          <AssetImage src="g2-0.png" />
        </TopItem>
        <TopItem>
          <SvgG21 />
        </TopItem>
        <TopItem>
          <SvgG22 />
        </TopItem>
      </TopContainer>
      <Text1>
        初めてのご使用の前に
      </Text1>
      <Text2>
        最初にご使用される際は、顔の目立ちにくい場所（フェイスラインなど）で様子をみてください。赤みや皮むけが表れなかった場合は少量づつ使い初めてください。
      </Text2>
      <Note1>
        ※お肌が敏感な方、アレルギーをお持ちの方は、使用前に製品のパッチテストを実施していただくことをおすすめします。
      </Note1>
    </Inner>
    <Inner>
      <Text1>
        ご使用方法
      </Text1>
      <UsageContainer>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi2-1.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 1
          </StepLabel>
          <StepText>
          使い始めは小豆１粒大のクリームを手にとります。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi2-2.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 2
          </StepLabel>
          <StepText>
          化粧水、美容液などで整えた肌に、顔の内側から外側に向かって塗布し優しく馴染ませます。
          <small>
            ※デリケートな目元・口元・小鼻周りには<br />
            レチノールサンドイッチの使用方法がおすすめ
          </small>
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi2-3.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 3
          </StepLabel>
          <StepText>
            使い始めは毎日の使用は控えて、3日おきを目安にお使いください。レチノールに肌が慣れてきたら少しづつ使用量をパール大１粒へ徐々に増やしてください。パール１粒３日おきに肌が慣れてきましたら間隔を1日おきなどへ増やすことや濃度のグレードアップの検討も可能です。
          </StepText>
        </StepItem>
      </UsageContainer>
      <Note2>
        ※使用中は、紫外線ダメージを受けやすいため、日中は日焼け止め等で紫外線対策をおこなってください。
      </Note2>
    </Inner>
    <Inner> 
      <Text1>
        必ずお読みください
      </Text1>
      <List>
        <Item>反応が現れた場合は、使用を止めて症状が落ち着いてから徐々に使用量や頻度を下げて使用し始めてください。<br />症状が続く場合や悪化する場合は、お客様窓口にお問い合わせください。</Item>
        <Item>重ね塗りや急激に多い量を使うとA反応が起こりやすくなるためゆっくりと肌をならしてください</Item>
        <Item>赤み・皮むけは症状が現れるまで1日〜2日かかります。<br />翌日反応がない理由に毎日や量を増やしてご使用されると反応が出てしまう可能性がありますので、ゆっくり徐々に増やしてください。</Item>
      </List>
      <BottomContainer>
        <BottomText>
          肌が慣れてきたり、物足りない方はグレードアップの検討も可能です。
        </BottomText>
        <Button href={`${themeFileRootPath}/assets/SKNREMED_BRAND_GUIDE_BOOK.pdf`}>
          詳しくはこちら
        </Button>
      </BottomContainer>
    </Inner>
  </Outer>
}
export const NiacinaGlowHowTo: FunctionComponent<HowToProps> = () => {
  return <Outer style={{'--color': '#D9AFCB'}}>
    <Inner>
      <TopContainer>
        <TopItem>
          <AssetImage src="g3-0.png" />
        </TopItem>
        <TopItem>
          <SvgG31 />
        </TopItem>
        <TopItem>
          <SvgG32 />
        </TopItem>
      </TopContainer>
    </Inner>
    <Inner>
      <Text1>
        ご使用方法
      </Text1>
      <UsageContainer>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi3-1.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 1
          </StepLabel>
          <StepText>
            大きめパール１粒大のジェルを手にとります。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi3-2.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 2
          </StepLabel>
          <StepText>
            スキンケアの最後に顔の内側から外側に向かって塗り広げてください。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi3-3.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 3
          </StepLabel>
          <StepText>
            乾燥が気になる場合は、重ね塗りしていただくことをおすすめします。
          </StepText>
        </StepItem>
      </UsageContainer>
      <StepBoxNote>
        ★ スキンサイクルメソッド中は、普段のスキンケアに加えて、朝・夜2回ご使用いただきより保湿していただくことをおすすめします。
      </StepBoxNote>
    </Inner>
    <Inner> 
      <Text1>
        集中ケア中のおすすめの使い方
      </Text1>
      <HorizontalContainer>
        <SvgContainer>
           <SvgG33 />
        </SvgContainer>
        <TextConteiner>
          <Text3>
            レチノールサンドイッチとは？
          </Text3>
          <Text4>
            こちらも皮膚科医が開発した、レチノール初心者の方・敏感肌の方におすすめの使用方法。顔全体・A反応の起きやすい部分に、ジェル→レチノール→ジェルで<br />
            サンドイッチすることでジェルがバリアになってA反応が起こりにくい使用方法。
          </Text4>
          <Note3>
          特に反応の出やすい目元 / 口元 / 小鼻周りは最初はこの方法でのご使用がおすすめ
          </Note3>
        </TextConteiner>
      </HorizontalContainer>
    </Inner>
  </Outer>
}
export const HydroLightHowTo: FunctionComponent<HowToProps> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer style={{'--color': '#A98EBB'}}>
    <Inner>
      <TopContainer>
        <TopItem>
          <AssetImage src="g4-0.png" />
        </TopItem>
        <TopItem>
          <SvgG41 />
        </TopItem>
        <TopItem>
          <SvgG42 />
        </TopItem>
      </TopContainer>
    </Inner>
    <Inner> 
      <Text1>
        パッチテストのやり方
      </Text1>
      <Note2>
        初めて使用の方は、必ず使用前にパッチテストをおこなってください。
      </Note2>
      <UsageContainer>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi4-4.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 1
          </StepLabel>
          <StepText>
            入浴後、 二の腕の内側に少量 (約1cm円形)を塗布してください。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi4-5.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 2
          </StepLabel>
          <StepText>
            30分後に塗った部分に赤み・かゆみ等の異常がないか確認し、異常がなければ24~48時間そのままの状態で、赤み・はれ・かゆみ・刺激・色抜け(白斑など)や黒ずみなどの異常が生じていないか様子をみて確認してください。二の腕で問題がない場合は、 フェイスラインの目立たない部分に少量を塗布して状がないか同様のテストをおこなって確認してください。
          </StepText>
        </StepItem>
      </UsageContainer>
      <Note2>
        ※パッチテスト中は、塗った部分をぬらしたり、こすったりしないようにご注意ください。<br />
        ※テスト後、特にお肌に変化がなければ、トラブルの可能性が少ないと思われますが、すべての方に合うという事ではありません。また、お肌は体調や環境の変化によって突然合わなくなる可能性もあります。ご使用時には、お肌に異常が生じていないか注意しながらご使用いただき、お肌に合わないときは、ご使用をおやめください。
      </Note2>
    </Inner>
    <Inner>
      <Text1>
        ご使用方法
      </Text1>
      <UsageContainer>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi4-1.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 1
          </StepLabel>
          <StepText>
            パール１粒大のクリームを手にとります。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi4-2.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 2
          </StepLabel>
          <StepText>
            化粧水、美容液などで整えた肌に、顔の気になる部分に塗布し優しく馴染ませます。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi4-3.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 3
          </StepLabel>
          <StepText>
            容器の口元をきれいにふき、必ずしっかりキャップを閉めてから冷蔵庫で保管してください。
          </StepText>
        </StepItem>
      </UsageContainer>
      <Note2>
        ※使用中は、紫外線ダメージを受けやすいため、日中は日焼け止め等で紫外線対策をおこなってください。
      </Note2>
    </Inner>
    <Inner>
      <Text1>
        必ずお読みください
      </Text1>
      <List>
        <Item>肌が敏感な状態の時、美容施術の前後（前後1週間） 、皮膚科で治療を受けている期間、長時間紫外線を浴びる予定がある時の使用は控えてください。</Item>
        <Item>使用の際、まれに赤み等の異常が出ることがあります。軽い刺激ですぐにおさまるものであれば、ご使用いただけます。ピリピリ感が治らない場合、赤み・刺激感・かゆみなどのアレルギーのような反応、強い刺激や他の症状がある場合には一旦使用を中止し、様子を見てください。使用を中止しても治まらない場合は、皮膚科専門医にご相談ください。</Item>
        <Item>ハイドロライトの美容クリームのケアは、お肌への効果と安全性を考え、4ヵ月の継続使用後、4ヵ月のお休み期間が必要となります。</Item>
        <Item>エアレス容器の特性上、初めて使用するときや、使用中にまれにポンプが空打ちになることがあります。その際は、内容物が出るまで数回プッシュしてください。</Item>
        <Item>冷蔵庫の中でも温度が下がり過ぎたり、冷風が直接当たる場所に保管されるとハイドロキノンがまれに結晶化することがあります。結晶化した場合には冷暗所で、１～2日保管し結晶が無くなってからお使いください。</Item>
        <Item>配合している成分（ハイドロキノン）は、高温や酸化、光の影響で変色する恐れがあります。茶色く変色したものの使用は控えてください。まれにポンプ内に残った内容物が変色することがありますが、その際は、数回プッシュして変色した部分を捨ててから使用してください。</Item>
      </List>
      <BottomContainer>
        <BottomText>
          2.5が肌に合っていた方、しっかりスポットケアしたい方はグレードアップの検討も可能です。
        </BottomText>
        <Button href={`${themeFileRootPath}/assets/SKNREMED_BRAND_GUIDE_BOOK.pdf`}>
          詳しくはこちら
        </Button>
      </BottomContainer>
    </Inner>
  </Outer>
}
export const DermaSerumUvHowTo: FunctionComponent<HowToProps> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer style={{'--color': '#b1aead'}}>
    <Inner>
      <TopContainer>
        <TopItem>
          <AssetImage src="g5-0.png" />
        </TopItem>
        <TopItem>
          <SvgG51 />
        </TopItem>
        <TopItem>
          <SvgG52 />
        </TopItem>
      </TopContainer>
    </Inner>
    <Inner>
      <Text1>
        ご使用方法
      </Text1>
      <UsageContainer>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi5-1.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 1
          </StepLabel>
          <StepText>
            パール2粒分大のクリームを手に取ります。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi2-2.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 2
          </StepLabel>
          <StepText>
            顔の内側から外側に向かって塗り広げてください。
          </StepText>
        </StepItem>
        <StepItem>
          <StepImageContainer>
            <AssetImage src="gi1-3.png" />
          </StepImageContainer>
          <StepLabel>
            STEP 3
          </StepLabel>
          <StepText>
            汗をかいたり、タオルでふいた後など必要に応じて塗りなおしてください。
          </StepText>
        </StepItem>
      </UsageContainer>
    </Inner>
  </Outer>
}