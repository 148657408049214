import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ProductInfo, ProductInfoData } from '../components/product-info'
import { ProductPageOuter } from '../components/product-page-components'
import { ProductStepSection, ProductStepSectionData } from '../sections/product-step-section'
import SvgProductStepGraph04 from '../generated/svg/product-step-graph-04'
import { VerticalSpacer } from '../components/spacer'
import { ProductVariationSection, ProductVariationSectionData } from '../sections/product-variation-section'
import { TopCounselingSection } from '../sections/top-counseling-section'
import { ProductDetailSection, ProductDetailSectionData } from '../sections/product-detail-section'
import { SetProductsSectionRetinoDermo01 } from '../sections/set-products-section'
import { UseSection } from './use-section'
import { HowToSection } from '../sections/how-to-section'
import { ProductBannerRetinoDermSection } from '../sections/product-banner-section'
import { CycleSection1 } from '../sections/cycle-section'
import { UsersVoiceSection } from '../sections/users-voice-section'
import { SubscriptionBannerSection } from '../sections/subscription-banner-section'
import { LineUpSection } from '../sections/line-up-section'
import { RetinoDermoSection } from '../sections/retino-dermo-section'
import { productSku } from '../product-sku'
import { skuToUrl } from '../utils/sku-to-url'
import { useSettings } from '../hooks/use-settings'

const productInfoData: ProductInfoData = {
  images: [
    'p2-1.png'
  ],
  brandName: 'SKN REMED',
  name: <>
    RetinoDerm™ 0.1
  </>,
  nameJa: 'レチノダーマクリーム0.1',
  info: [
    {
      label: '内容量',
      value: '20g'
    }
  ],
  variations: [
    {
      label: '0.1',
      color: '#EFBAB4',
      link: skuToUrl(productSku.retinoDermo01.single),
      active: true
    },
    {
      label: '0.2',
      color: '#EE958F',
      link: skuToUrl(productSku.retinoDermo02.single),
    },
    {
      label: '0.4',
      color: '#E98760',
      link: skuToUrl(productSku.retinoDermo04.single),
    }
  ],
  checkoutNote: <>
    <ul>
      <li>妊娠中、授乳中は肌が敏感になりやすいためご使用をお控えいただくことをおすすめしています。<br /></li>
      <li>お肌が敏感な方、アレルギーをお持ちの方は、使用前に製品のパッチテストを実施していただくことをおすすめします。</li>
    </ul>
  </>,
  singleSku: productSku.retinoDermo01.single,
  subscriptionSku: productSku.retinoDermo01.subscription,
  notice: <>
    <ul>
      <li>肌が敏感な状態の時、アトピー体質、ケロイド体質の方はご使用をお控えください。</li>
      <li>妊娠中、授乳中は肌が敏感になりやすいためご使用をお控えいただくことをおすすめしています。</li>
      <li>使用中は、紫外線ダメージを受けやすいため、日中は日焼け止め等で紫外線対策をおこなってください。</li>
      <li>肌が敏感な状態の時、美容施術の前後（前後1週間） 、皮膚科で治療を受けている期間、長時間紫外線を浴びる予定がある時の使用は控えてください。</li>
    </ul>
  </>
}
export const productStepSectionData: ProductStepSectionData = {
  backgroundImg: 'p2-12.png',
  graph: <SvgProductStepGraph04 />,
  step: 2,
  stepColor: '#E98760',
  text1: <>
    RetinoDerm™ Cream
  </>,
  text2: <>
    高濃度の集中ケア
  </>,
  text3: <>
    レチノダーマクリームは、レチノール<sup>※</sup>配合の美容クリームです。スキンサイクルメソッドではDAY.2に使用を推奨。ハリとツヤにあふれる肌に仕上げます。（ハイドロライトクリームとの併用はできません）
  </>,
  note: <>
    ※整肌成分
  </>
}

export const productVariationSectionData: ProductVariationSectionData = {
  items: [
    {
      img: 'p2-4.png',
      name: <>
        RetinoDerm™ 0.1
      </>,
      text: <>
        ハリやツヤの不足が気になり、毎日のケアが物足りなく感じている方向け。レチノール※1初心者におすすめのアイテム。
      </>,
      list: [
        {
          label: '使用方法(目安)',
          items: [
            '小豆1粒～',
            '慣れてきたらパール1粒へ'
          ]
        },
        {
          label: '使用回数',
          items: [
            '慣れてきたら毎日使用も可'
          ]
        },
        {
          label: '購入条件',
          items: [
            '初めての方でも購入可能。'
          ]
        }
      ],
      night: true,
      color: '#EFBAB4'
    },
    {
      img: 'p2-5.png',
      name: <>
        RetinoDerm™ 0.2
      </>,
      text: <>
        乾燥による肌のゴワつき、毛穴の目立ち、くすみが気になるかたに。攻めと守りのバランスを意識した、中級者向けのアイテム。
      </>,
      list: [
        {
          label: '使用方法(目安)',
          items: [
            '小豆1粒～',
            '慣れてきたらパール1粒へ'
          ]
        },
        {
          label: '使用回数',
          items: [
            '1～3日に1回'
          ]
        },
        {
          label: '購入条件',
          items: [
            '初めての方でも購入可能。',
            'レチノール製品をまだお使いいただいたことのない方は0.1からのご使用をおすすめします。'
          ]
        }
      ],
      night: true,
      color: '#EE958F'
    },
    {
      img: 'p2-6.png',
      name: <>
        RetinoDerm™ 0.4
      </>,
      text: <>
        年齢を感じる肌に、攻めのレチノール※1ケアをしたい方向け。レチノール※1上級者向けのアイテム。
      </>,
      list: [
        {
          label: '使用方法(目安)',
          items: [
            '小豆1粒～',
            '慣れてきたらパール1粒へ'
          ]
        },
        {
          label: '使用回数',
          items: [
            '1～3日に1回'
          ]
        },
        {
          label: '購入条件',
          items: [
            'レチノダーマ0.1または0.2のご使用後購入可能。',
          ]
        }
      ],
      night: true,
      color: '#E98760'
    },
  ],
  text1: <>
    3段階のピュアレチノール<sup>※1</sup>グレード
  </>,
  text2: <>
    レチノールは、私たちの肌に存在するビタミンAの一種。 
    すべてのグレードでピュアレチノール<sup>※1</sup>を配合しながら、それぞれの肌へ適した3段階の処方濃度を用意しています。
  </>,
  text3: <>
    パール１粒３日おきに肌が慣れてきましたら、間隔を1日おきなどへ増やすことやレチノール使用２ヶ月ほどでグレードアップの検討も可能です。
  </>,
  note: <>
    ※1 整肌成分
  </>
}

export const productDetailSectionData: ProductDetailSectionData = {
  img1: 'p2-8.png',
  img2: 'p2-9.png',
  img3: 'p2-10.png',
  img4: 'p2-11.png',
  text1: <>
    ５種のレチノダーマブレンド<sup>※</sup>
  </>,
  text2: <>
    レチノール<sup>※</sup>の機能と肌への負担を考えて、グレードごとに同じ成分でも
    「攻めの成分」と「守りの成分」を独自のバランスで配合しています。
    グレードに合わせて、各成分が肌に負担をかけすぎずに機能するよう設計しました。
  </>,
  text3: <>
    攻めながらもしっかりと保水し<br />
    健やかな肌へ
  </>,
  text4: <>
    集中ケアの時にかかる肌への負担を考えて、保水成分をたっぷりと配合。
    角質層を保水ケアできる、 サイズ違いの【4種のヒアルロン酸<sup>※1</sup>】
    さらに、潤いバリアをサポートする【5種のセラミド配合カプセル<sup>※2</sup>】を配合し、乾燥から肌を守ります。
  </>,
  note1: <>
    ※1アセチルヒアルロン酸Ｎａ、ヒアルロン酸Ｎａ、ヒアルロン酸クロスポリマー－２－Ｎａ、加水分解ヒアルロン酸Ｎａ<br />
    （すべて保湿成分）<br />
    ※2セラミドＮＧ、セラミドＡＰ、セラミドＡＧ、セラミドＮＰ、セラミドＥＯＰ（すべて保湿成分）<br />
    ※ 整肌成分
  </>,
  text5: <>
    成分品質を守るエアレスチューブ
  </>,
  text6: <>
    酸素や光に弱く、安定性の悪いレチノール<sup>※</sup>を配合した美容液のため、エアレス容器を採用しました。
    空気に触れにくい密封性の高い仕様のため、最後までピュアレチノール<sup>※</sup>を守ります。
    未開封、開封後ともに冷暗所保存がおすすめです。
  </>,
  note2: <>
    ※ 整肌成分
  </>
}

type ProductRetinoDermo01PageProps = {

}
export const ProductRetinoDermo01Page: FunctionComponent<ProductRetinoDermo01PageProps> = () => {
  return <ProductPageOuter>
    <ProductInfo
      data={productInfoData}
    />
    <VerticalSpacer length='4rem' />
    <ProductStepSection
      data={productStepSectionData}
    />
    <VerticalSpacer length='4rem' />
    <ProductVariationSection
      data={productVariationSectionData}
    />
    <VerticalSpacer length='4rem' />
    <RetinoDermoSection />
    {/* <VerticalSpacer length='4rem' />
    <TopCounselingSection /> */}
    <VerticalSpacer length='8rem' />
    <ProductDetailSection
      data={productDetailSectionData}
    />
    <VerticalSpacer length='8rem' />
    <SetProductsSectionRetinoDermo01 />
    <VerticalSpacer length='8rem' />
    <UseSection />
    <VerticalSpacer length='8rem' />
    <HowToSection use={['peelCare', 'retinoDerm', 'niacinaGlow']} initial="retinoDerm"/>
    <VerticalSpacer length='8rem' mLength='4rem' />
    <ProductBannerRetinoDermSection />
    <VerticalSpacer length='8rem' mLength='4rem' />
    <CycleSection1 />
    {/* <VerticalSpacer length='8rem' mLength='4rem' />
    <UsersVoiceSection /> */}
    <VerticalSpacer length='8rem' mLength='4rem' />
    <ProductInfo
      data={productInfoData}
    />
    <VerticalSpacer length='8rem' mLength='4rem' />
    <SubscriptionBannerSection color="#EBD8D0"/>
    <VerticalSpacer length='8rem' mLength='4rem' />
    <LineUpSection />
  </ProductPageOuter>
}