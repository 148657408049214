import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { rightSideMaxWidth } from '../styles/page-max-width'
import { AssetImage } from '../components/image'
import { AnimateStep } from '../components/animate-step'

const Outer = styled.div`
  ${rightSideMaxWidth}
  background-color: #F4F4F4;
  position: relative;
  border-radius: 3rem 0 0 3rem;
  @media (max-width: 40rem){
    border-radius: 2rem;
  }
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;
  padding: 6rem 0;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`

const Bg = styled.div`
  position: absolute;
  mix-blend-mode: darken;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  img {
    display: block;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 110%;
    object-fit: cover;
  }
`

const LeftContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

const RightContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Step = styled.div`
  width: 3.625rem;
  height: 3.625rem;
  color: white;
  font-family: var(--serif-en-font);
  font-weight: 400;;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10rem;
  line-height: 1.25;
  padding-top: 0.25rem;
  @media (max-width: 40rem){
    display: none;
  }
  span {
    font-size: 0.875rem;
    letter-spacing: 0.01em;
  }
`

const Text1 = styled.div`
  font-size: 1.375rem;
  line-height: 1.7272727272727273;
  letter-spacing: 0.06em;
  font-family: var(--en-font);
  font-weight: 700;
  margin-top: 1rem;
  @media (max-width: 40rem){
  }
`

const Text2 = styled.div`
  font-size: 1.375rem;
  line-height: 1.7272727272727273;
  letter-spacing: 0.06em;
  font-weight: 700;
  margin-top: -0.25rem;
  @media (max-width: 40rem){
    line-height: 1.9375;
    font-size: 1rem;
  }
`

const Text3 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1.5rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 2.2857142857142856;
  }
`

const Note = styled.div`
  font-size: 0.5625rem;
  line-height: normal;
  margin-top: 1rem;
  @media (max-width: 40rem){
    font-size: 0.5625rem;
    line-height: 1.4444444444444444;
  }
`

export type ProductStepSectionData = {
  backgroundImg: string,
  stepColor: string,
  graph: ReactNode,
  step?: number | string,
  text1: ReactNode,
  text2: ReactNode,
  text3: ReactNode,
  note?: ReactNode
}

type ProductStepSectionProps = {
  data: ProductStepSectionData
}
export const ProductStepSection: FunctionComponent<ProductStepSectionProps> = ({data}) => {
  return <Outer>
    <Inner>
      <Bg>
        <AssetImage src={data.backgroundImg} />
      </Bg>
      <LeftContainer>
        <AnimateStep>
          {data.graph}
        </AnimateStep>
      </LeftContainer>
      <RightContainer>
        {
          data.step && 
          <Step style={{backgroundColor: data.stepColor}}>
            <span>DAY</span>
            {data.step}
          </Step>
        }
        <Text1>
          {data.text1}
        </Text1>
        <Text2>
          {data.text2}
        </Text2>
        <Text3>
          {data.text3}
        </Text3>
        {
          data.note && <Note>
            {data.note}
          </Note>
        }
      </RightContainer>
    </Inner>
  </Outer>
}