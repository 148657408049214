import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { AssetImage } from '../components/image'
import { pageMaxWidthWithPadding } from '../styles/page-max-width'

const Outer = styled.div``

const Inner = styled.div`
  ${pageMaxWidthWithPadding}
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const ImageContainer = styled.div`
  position: relative;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Badge = styled.div`
  position: absolute;
  font-family: var(--en-font);
  line-height: normal;
  width: 6rem;
  height: 6rem;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20rem;
  color: white;
  text-align: center;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    width: 5rem;
    height: 5rem;
    top: 1rem;
    left: 1rem;
  }
`

const TextContainer = styled.div`
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  @media (max-width: 40rem){
    padding: 2rem;
  }
`

const Text = styled.div`
  line-height: 1.8125;
  font-weight: 700;
  small {
    display: block;
    font-size: 0.75rem;
  }
`

const Note = styled.div`
  font-size: 0.5625rem;
  line-height: normal;
  margin-top: 2rem;
  @media (max-width: 40rem){
    margin-top: 1rem;
  }
`

type ProductBannerSectionProps = {
  data: {
    image: string,
    badgeText: ReactNode,
    badgeBackground: string,
    text: ReactNode,
    note: ReactNode
  }
}
export const ProductBannerSection: FunctionComponent<ProductBannerSectionProps> = ({data}) => {
  return <Outer>
    <Inner>
      <ImageContainer>
        <AssetImage src={data.image} />
        <Badge style={{backgroundImage: data.badgeBackground}}>
          {data.badgeText}
        </Badge>
      </ImageContainer>
      <TextContainer>
        <Text>
          {data.text}
        </Text>
        <Note>
          {data.note}
        </Note>
      </TextContainer>
    </Inner>
  </Outer>
}

export const ProductBannerPeelCareSection: FunctionComponent = () => {
  return <ProductBannerSection
    data={{
      image: 'p1-7.png',
      badgeText: <>
        PeelCare<br />
        Serum
      </>,
      badgeBackground: 'linear-gradient(to right bottom, #B7CEB4, #8EB2C0, #5395A1)',
      text: <>
        普段お使いのスキンケアに<br />
        アイテムを取り入れてください。<br />
        使用後は保湿ケアを入念におこないましょう。
      </>,
      note: <>
        ※ 使用方法・回数・量をしっかり確認し、肌の様子をみながらご使用ください。<br />
      </>
    }}
  />
}

export const ProductBannerRetinoDermSection: FunctionComponent = () => {
  return <ProductBannerSection
    data={{
      image: 'p2-7.png',
      badgeText: <>
        Retino<br />
        Derm™<br />
        Cream
      </>,
      badgeBackground: 'linear-gradient(to right bottom, #EFBAB4, #EFBAB4)',
      text: <>
        普段お使いのスキンケアに<br />
        アイテムを取り入れてください。<br />
        レチノールに肌が慣れてきたら慎重に<br />
        少しづつ使用量を徐々に増やしてください。
      </>,
      note: '※ 使用方法・回数・量をしっかり確認し、肌の様子をみながらご使用ください。'
    }}
  />
}

export const ProductBannerHydroLightSection: FunctionComponent = () => {
  return <ProductBannerSection
    data={{
      image: 'p3-5.png',
      badgeText: <>
        Hydro
        Light™
      </>,
      badgeBackground: 'linear-gradient(to right bottom, #C6B7D8, #A98EBB)',
      text: <>
        普段お使いのスキンケアに<br />
        アイテムを取り入れてください。
      </>,
      note: <>
        ※ 赤み・皮むけは症状が現れるまで1日〜2日かかります。ゆっくり徐々に増やしてください。<br />
        ※ 使用方法・回数・量をしっかり確認し、肌の様子をみながらご使用ください。
      </>
    }}
  />
}

export const ProductBannerNiacinaGlowSection: FunctionComponent = () => {
  return <ProductBannerSection
    data={{
      image: 'p4-2.png',
      badgeText: <>
        Niacina
        Glow™
      </>,
      badgeBackground: 'linear-gradient(to right bottom, #D9AFCB, #D9AFCB)',
      text: <>
        普段お使いのスキンケアに<br />
        アイテムを取り入れてください。<br />
        集中ケア後のお手入れ、おやすみ期間中のお手入れ、<br />
        どちらにも使用可能です。
      </>,
      note: <>
        ※ 使用方法・回数・量をしっかり確認し、肌の様子をみながらご使用ください。
      </>
    }}
  />
}

export const ProductDermaSerumUvSection: FunctionComponent = () => {
  return <ProductBannerSection
    data={{
      image: 'p5-1.png',
      badgeText: <>
        Derma
        Serum UV
      </>,
      badgeBackground: 'linear-gradient(to right bottom, #b1aead, #757073)',
      text: <>
        普段お使いのスキンケアに<br />
        アイテムを取り入れてください。<br />
        <small>
          ★ピールケアセラムやレチノダーマクリームは、<br />
          肌サイクルにアプローチしてくれるので、<br />
          使用期間中は紫外線ダメージを受けやすいため、<br />
          日中は日焼け止め等で紫外線対策を<br />
          しっかりおこなっていただくことを推奨しております。
        </small>
      </>,
      note: <>
        ※ 使用方法・回数・量をしっかり確認し、肌の様子をみながらご使用ください。
      </>
    }}
  />
}